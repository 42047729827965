import { useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"

import Marquee from "react-marquee-slider"
import { getRandomElement } from "../../utils/random"

import Img from "gatsby-image"

const headlineOptions = [
  "8 Benefits Of AAA",
  "8 Simple Ways to Travel Smarter",
  "8 Smart Travel Tips for the Perfect Trip",
  "8 Travel Tips to Help You Travel Smarter",
  "A Quick Benefits Guide to AAA Membership Levels",
  "AAA Membership: Is It Worth the Cost?",
  "Perks of AAA Membership Most Don't Know",
  "Top Discounts that AAA Members Sometimes Forget",
  "Watch Out for These 5 Surprise Travel Expenses!",
]

const INTERVAL = 3000
const NUM_VARIATIONS = 6

const VariationsSection = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "creatives" } }) {
        nodes {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 100
              quality: 100
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const Image = ({ image, ...props }) => {
    return <Img fluid={image} {...props} />
  }

  const images = query.allFile.nodes.map(node => node.childImageSharp.fluid)

  const getRandomVariations = count =>
    Array.from({ length: count }, (_, i) => i).map(() => ({
      headline: getRandomElement(headlineOptions),
      image: getRandomElement(images),
    }))

  const [variations, setVariations] = useState(
    getRandomVariations(NUM_VARIATIONS)
  )

  const [oldVariations, setOldVariations] = useState([])

  const [count, setCount] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOldVariations(variations)
      setVariations(getRandomVariations(NUM_VARIATIONS))
      setCount(count + 1)
    }, INTERVAL)
    return () => clearTimeout(timeout)
  }, [variations, count])

  return (
    <div className="variations-section">
      <div
        className="is-hidden-touch"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          position: "relative",
          zIndex: 2,
          gap: "1em",
        }}
      >
        {variations.map((variation, index) => (
          <div
            className="card animated zoomIn"
            style={{
              height: "10em",
              animationDelay: `${0.1 * index}s`,
            }}
            key={"" + count + "." + index}
          >
            <div
              style={{ animationDelay: `${0.6 + 0.1 * index}s` }}
              className="star animated zoomOut"
            ></div>
            <div className="card-image">
              <Image
                image={variation.image}
                style={{ width: "100%", aspectRatio: "2/1" }}
              />
            </div>
            <div className="card-content p-4">
              <p className="title is-7 has-text-weight-medium">
                {variation.headline}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div
        className="is-hidden-touch"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "1em",
          position: "absolute",
          transform: "translateY(-100%)",
        }}
      >
        {oldVariations.map((variation, index) => (
          <div
            className="card animated fadeOut"
            style={{
              height: "10em",
              animationDelay: `${0.1 * index}s`,
              animationDuration: "0.5s",
            }}
            key={"" + (count - 1) + "." + index}
          >
            <div className="card-image">
              <Image
                image={variation.image}
                style={{ width: "100%", aspectRatio: "2/1" }}
              />
            </div>
            <div className="card-content p-4">
              <p className="title is-7 has-text-weight-medium">
                {variation.headline}
              </p>
            </div>
          </div>
        ))}
      </div>
      {/* Mobile Version */}
      <div className="is-hidden-desktop">
        <Marquee velocity={8} style={{ display: "flex" }}>
          {variations.map((variation, index) => (
            <div
              className="card"
              key={"" + index}
              style={{ width: 200, height: 180, margin: "0 1em" }}
            >
              <div className="card-image" style={{ position: "relative" }}>
                <Image
                  className="animated fadeInDown"
                  key={"img" + count + "." + index}
                  image={variation.image}
                  style={{
                    width: "100%",
                    aspectRatio: "2/1",
                    animationDelay: index * 0.1 + "s",
                  }}
                />
                <Image
                  className="animated zoomOut"
                  key={"img" + (count - 1) + "." + index}
                  image={oldVariations[index]?.image}
                  style={{
                    width: "100%",
                    position: "absolute",
                    aspectRatio: "2/1",
                    top: 0,
                    left: 0,
                    animationDelay: index * 0.1 + "s",
                  }}
                />
              </div>
              <div
                className="card-content p-4"
                style={{ position: "relative" }}
              >
                <p
                  key={"title" + count + "." + index}
                  className="title is-7 has-text-weight-medium animated fadeIn"
                  style={{
                    position: "absolute",
                    top: "1rem",
                    width: "85%",
                    animationDelay: index * 0.1 + "s",
                  }}
                >
                  {variation.headline}
                </p>
                <p
                  key={"title" + (count - 1) + index}
                  className="title is-7 has-text-weight-medium animated zoomOut"
                  style={{
                    position: "absolute",
                    top: "1rem",
                    width: "85%",
                    animationDelay: index * 0.1 + "s",
                  }}
                >
                  {oldVariations[index]?.headline}
                </p>
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  )
}

export default VariationsSection
