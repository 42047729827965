export const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min
export const getRandomElement = list => list[getRandomInt(0, list.length - 1)]
export const getRandomBoolean = probOfTrue => Math.random() < probOfTrue

export const normalRandom = (mean = 0, deviation = 1) => {
  const u = 1 - Math.random() // Converting [0,1) to (0,1]
  const v = Math.random()
  const z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)
  // Transform to the desired mean and standard deviation:
  return z * deviation + mean
}

export const logNormalRandom = (mean = 0, deviation = 1) => {
  return Math.exp(normalRandom(mean, deviation))
}
